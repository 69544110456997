<template>
  <div class="config-panel">
    <div style="height: 20px">
      <span>指定物业员工显示“会议室”</span>
      <div class="tree-wrap">
        <CSSelect>
          <div
              :class="['room-select', {'unselect': Object.keys(checkedStaffs).length === 0}]"
              @click.stop="staffTreeVisible = true"
          >
            <template v-if="Object.keys(checkedStaffs).length > 0">
              已选{{Object.keys(checkedStaffs).length}}人
            </template>
            <template v-else>
              请选择
            </template>
          </div>
          <div @click.stop>
            <TreePanel v-show="staffTreeVisible">
              <CSTree :tree="staffTreeData" @change="changeStaff" />
            </TreePanel>
          </div>
        </CSSelect>
      </div>
    </div>
    <div class="tip">
        <svg
            class="icon"
            aria-hidden="true"
        >
            <use xlink:href="#icon-menua-zu92"></use>
        </svg>
        指定的物业员工将会显示“会议室”，用于开会议室的门，进行卫生打扫，设备检修等。</div>
    <div>
      <button class="btn btn-primary" @click="setMeetingRoomConfig">保存</button>
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";
import {getIsSettOpenRoomUrl, setStaffOpenRoomUrl} from "@/requestUrl";

export default {
  name: "MeetingRoomConfig",
  components: {
    CSSelect,
    TreePanel,
    CSTree,
  },
  mounted() {
    document.body.addEventListener('click', () => {
      this.staffTreeVisible = false;
    })
    this.getStaffList();
  },
  data() {
    return {
      regionId: this.$vc.getCurrentRegion().code,
      staffList: [],
      staffTreeVisible: false,
      staffTreeData: [],
      checkedStaffs: {},
      canOpenRoomStaffs: {},
    }
  },
  methods: {
    getOpenRoomStaff() {
      return this.$fly.get(getIsSettOpenRoomUrl, {
        staffUserId: '',
        regionId: this.regionId,
      })
        .then(res => res.data || [])
    },
    // 获取员工列表
    async getStaffList() {
      let canOpenRoomStaffs = {};
      this.checkedStaffs = {};
      const canOpenRoomRes = await this.getOpenRoomStaff();
      canOpenRoomRes.forEach(staff => {
        this.checkedStaffs[staff.staffUserId] = {
          id: staff.id,
          departmentId: staff.departmentId, //必填，部门编号
          departmentName: staff.departmentName, //必填，部门名称
          staffUserId: staff.staffUserId, //必填，员工编号
          staffName: staff.staffName, //必填，员工姓名
          staffOfficeName: staff.staffOfficeName, //必填，员工职位
          state: staff.state //设置状态  0-已设置  1-未设置
        };
        staff.state = Number(!staff.state);
        canOpenRoomStaffs[staff.staffUserId] = staff;
      });
      this.canOpenRoomStaffs = canOpenRoomStaffs;
      const departments = await this.$vc.getAllDepartmentList({});
      departments.map(async duty => {
        let children = await this.$vc.getAllStaffList({isOnTheJob: true, departmentCode: duty.code});
        children = children.map(child => {
          child.checked = false;
          if (canOpenRoomStaffs.hasOwnProperty(child.id)) {
            child.openId = canOpenRoomStaffs[child.id].id;
            child.checked = !!canOpenRoomStaffs[child.id].state;
          }
          child.allowCheck = true;
          return child;
        })
        const item = {
          ...duty,
          children,
          checked: false,
          allowCheck: true,
        };
        this.staffTreeData.push(item);
      })
    },
    changeStaff({key: index, source}) {
      const item = source[index];
      const checked = item.checked;
      let { checkedStaffs } = this;
      item.checked = !checked;
      if (item.children) {
        item.children.forEach(child => {
          this.changeCheckedStaff(item.checked, checkedStaffs, child);
          child.checked = item.checked;
        })
      } else {
        this.changeCheckedStaff(item.checked, checkedStaffs, item);
      }
      this.checkedStaffs = checkedStaffs;
    },
    /**
     * 更改选中的员工
     * @param {Boolean} checked 是否选中
     * @param {Object} checkedStaffs 所有选中的员工对象
     * @param {Object} item 当前选择的员工
     * */
    changeCheckedStaff(checked, checkedStaffs, item) {
      let {canOpenRoomStaffs } = this;
      if (checked) {
        const staff = {
          departmentId: item.departmentCode, //必填，部门编号
          departmentName: item.departmentName, //必填，部门名称
          staffUserId: item.id, //必填，员工编号
          staffName: item.name, //必填，员工姓名
          staffOfficeName: item.jobTitle, //必填，员工职位
          state: 0 //设置状态  0-已设置  1-未设置
        };
        if (item.openId) {
          staff.id = item.openId;
        }
        checkedStaffs[item.id] = staff;
      } else {
        if (canOpenRoomStaffs[item.id]) {
          checkedStaffs[item.id].state = 1;
        } else {
          delete checkedStaffs[item.id];
        }
      }
    },
    setMeetingRoomConfig() {
      const {checkedStaffs} = this;
      let params = Object.assign(Object.values(checkedStaffs).map(staff => {
        staff.regionId = this.regionId;
        return staff;
      }));
      this.$fly.post(setStaffOpenRoomUrl,  params)
        .then(res => {
          if (res.code !== 0) {
            return;
          }
          this.$vc.toast('设置成功');
          this.getStaffList();
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.config-panel
  background #fff
  padding 25px 20px
  min-height 300px
  & > div
    font-size 14px
    color #000
    & > span
      display inline-block
      margin-right 30px
      vertical-align middle
    &:not(:last-of-type)
      margin-bottom 20px
    &.tip
      color #999
.tree-wrap
  max-width 250px
  position relative
  display inline-block
  vertical-align middle
  .cs-input
    width 215px
.room-select
  width 215px
  overflow hidden
  height 40px
  display inline-flex !important
  flex-flow row nowrap
  align-items center
  line-height 40px
  padding 0 6px
  position relative
  &.unselect
    color #999
  span
    height 30px
    line-height 30px
    background #1ab394
    padding 0 10px
    text-align center
    display inline-block
    vertical-align middle
    color #fff
    border-radius 15px
    &:not(:last-of-type)
      margin-right 10px
</style>
